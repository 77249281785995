import camelcaseKeys from 'camelcase-keys';
import { CategoryType } from '@types';
import { CMS_ENDPOINT } from './constants';

export const preprocessCategory = (rawData: any[]): CategoryType => {
  const categories = camelcaseKeys(rawData, { deep: true, stopPaths: ['features'] });
  delete categories[0].products;
  return categories[0];
};

export const preprocessCategories = (rawData: any[]): CategoryType[] => rawData.map((data) => preprocessCategory([data]));

export const fetchPreprocessedCategory = async (slug: string): Promise<CategoryType> => {
  const response: Response = await fetch(`${CMS_ENDPOINT}/categories?slug=${slug}`);
  const categories: any = await response.json();
  if (categories.length === 0) throw new Error(`Selected ${slug} does not have category.`);
  return preprocessCategory(categories);
};
