import { useEffect, useRef, useState } from 'react';

export function useInterval(callback, delay) {
  const savedCallback = useRef();
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) {
        // @ts-ignore
        setEnabled(savedCallback.current());
      }
    }

    if (delay !== null && enabled) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}
