import nextSession from 'next-session';
import DynamoDbStore from './dynamodb-store';

/**
 * next-session configuration.
 * https://www.npmjs.com/package/next-session
 */
export const getSession = nextSession({
  store: new DynamoDbStore(),
  cookie: {
    secure: true,
    sameSite: 'lax',
  },
});
