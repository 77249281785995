import { LoadingLayout } from '@components';
import GoogleReCaptchaWidget from '../captcha/GoogleReCaptchaWidget';

const CloudflareTurnstile = () => (
  <div
    className='cf-turnstile mt-50'
    data-sitekey={process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY}
    data-theme='light'
    data-callback='setSiteverifyToken'
  />
);

const GoogleReCaptchaSpinner = ({ url }: Partial<Props>) : JSX.Element => (
  <div role='status'>
    <GoogleReCaptchaWidget redirectUrl={url} />
  </div>
);

export const InterstitialLoadingSection = ({
  url, emblemUrl, emblemAlt, turnstileEnabled,
}: Props) => (
  <div className='bg-white overflow-hidden md:rounded w-full h-full max-h-[600px] md:w-[600px] shadow-xl shadow-gray-400/60 border-t border-gray-100'>
    <LoadingLayout emblemUrl={emblemUrl} emblemAlt={emblemAlt}>
      {
        url ? (
          <>
            <h1 className='text-2xl font-medium text-[#565966] mb-2'>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              {turnstileEnabled ? 'Verifying your request' : 'Hang Tight!'}
            </h1>

            {turnstileEnabled
              ? <CloudflareTurnstile />
              : <GoogleReCaptchaSpinner url={url} />}
          </>
        ) : (
          <>
            <h1 className='text-2xl text-pink-500 font-medium '>
              Ooops... !
            </h1>

            <p className='text-[#565966] text-base text-center'>
              Redirect is not available
            </p>
          </>
        )
      }
    </LoadingLayout>
  </div>
);

type Props = { url: string | undefined; emblemUrl?: string; emblemAlt?: string, turnstileEnabled: boolean };
InterstitialLoadingSection.defaultProps = { emblemUrl: '/logo.png', emblemAlt: 'Comparison411 Logo' };
