import { useEffect, useRef } from 'react';

type LoadingPatternProps = {
    width: number,
    numberOfLines: number,
};

export function LoadingPattern({ width, numberOfLines }: LoadingPatternProps) {
  const ref = useRef<HTMLDivElement>();

  const generateRandomNumbers = (totalNumberOfElements) => {
    const alreadySelectedElements = [];
    return (numberOfElementsRequired) => {
      const randomNumbers = [];
      while (randomNumbers.length < numberOfElementsRequired) {
        const randomNumber = Math.floor(Math.random() * totalNumberOfElements) + 1;
        if (randomNumbers.indexOf(randomNumber) === -1
                    && alreadySelectedElements.indexOf(randomNumbers) === -1) {
          randomNumbers.push(randomNumber);
        }
      }
      return randomNumbers;
    };
  };

  useEffect(() => {
    if (ref.current) {
      const totalNumberOfElements = ref.current.childNodes.length;

      const randomNumberGenerator = generateRandomNumbers(totalNumberOfElements);

      const nodeLists: ChildNode[] = Array.from(ref.current.childNodes) || [];

      if (nodeLists && nodeLists.length > 0) {
        randomNumberGenerator(100).forEach((randomNumber) => {
          if (nodeLists[randomNumber]) {
            // @ts-ignore
            nodeLists[randomNumber].className = 'w-1 h-1 bg-blue-500 glow-animation-1';
          }
        });

        randomNumberGenerator(40).forEach((randomNumber) => {
          if (nodeLists[randomNumber]) {
            // @ts-ignore
            nodeLists[randomNumber].className = 'w-1 h-1 bg-blue-500 glow-animation-2';
          }
        });
      }
    }
  }, [ref]);

  return (
    <div className='flex flex-wrap overflow-hidden' ref={ref}>
      {
        Array
          .from(Array(width * numberOfLines).keys())
          .map((index) => (
            <div
              key={index}
              className={`
                        w-1 
                        h-1
                        bg-blue-500
                    `}
            />
          ))
      }
    </div>
  );
}
