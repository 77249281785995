import { DynamoDBClient } from '@aws-sdk/client-dynamodb';

/**
 * The shared config for all dynamo DB clients.
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-dynamodb/index.html
 */
export const DYNAMO_DB_CONFIG = process.env.NODE_ENV === 'production' ? {
  region: 'us-east-2',
  credentials: {
    accessKeyId: process.env.C411_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.C411_AWS_SECRET_ACCESS_KEY,
  },
} : { region: 'us-east-2' };

export const { C411_SESSION_TABLE } = process.env;
export const { C411_CLICKS_TABLE } = process.env;

/**
 * Default DynamoDB client
 */
export const dynamodb = new DynamoDBClient(DYNAMO_DB_CONFIG);
