import { useEffect, useRef } from 'react';
import { initWidget, WidgetType, Theme } from '@bluefinenterprises/bluefin-libs';
import { GEMINI_PIXEL_ID, GEMINI_PROJECT_ID } from '@lib';

declare global {
  interface Window {
    BFBootstrap: { init: (options: any) => void };
  }
}

type WidgetProps = {
    type: 'buyerZone' | 'growthHub',
    publisherId: string | null;
    sid: string;
    clickSlug: string | null;
    costPerLead: number | null;
    source: string;
    display: boolean;
    categoryName: string;
    decorate?: boolean;
};

export function Widget({
  source, type, sid, publisherId, clickSlug, costPerLead, display, categoryName, decorate,
}: WidgetProps) {
  const ref = useRef<HTMLDivElement>(null);
  const containerId = 'widget';
  const formProvider = type;

  useEffect(() => {
    let interval;
    if (ref.current && type === 'growthHub') {
      const resetStyle = () => {
        ref.current.style.position = 'unset';
        ref.current.style.zIndex = 'unset';
      };
      resetStyle();
      interval = setInterval(resetStyle, 500);
    }
    return () => {
      clearInterval(interval);
    };
  }, [ref, type]);

  useEffect(() => {
    initWidget({
      containerId,
      type: formProvider as WidgetType,
      clickSlug,
      analytics: {
        googleAds: {
          conversionId: 'AW-966612198',
          firstInteractionConversionLabel: 'xJ9oCOqouaQDEOap9cwD',
          leadConversionLabel: 'NzP3COvRrqQDEOap9cwD',
        },
        gemini: {
          projectId: GEMINI_PROJECT_ID,
          pixelId: GEMINI_PIXEL_ID,
        },
        costPerLead,
      },
      widgets: [
        {
          type: 'growthHub',
          pid: sid,
          splashImage: true,
          palette: Theme.BLUE_ORANGE,
        },
        {
          type: 'buyerZone',
          pubId: publisherId,
          catId: sid,
          source,
          palette: 'neptune',
        },
      ],
    });
  }, [type]);

  return (
    <div className={`bg-white z-30 relative rounded-b-md overflow-hidden ${type === 'buyerZone' && decorate ? 'border-4 border-gray-900' : ''} ${display === true ? 'block' : 'hidden'}`}>
      {
        type === 'buyerZone' && decorate && (
          <div className='bg-gray-900 flex flex-col-reverse gap-y-2 md:gap-y-0 md:flex-row justify-between items-center px-4 py-3'>
            <p className='text-lg md:text-2xl text-white font-semibold'>
              Top 10
              {' '}
              {categoryName}
              {' '}
              Providers
            </p>

            <img
              src='/line-logo-white.svg'
              alt='Comparison411 Logo'
              className='max-h-12'
            />

          </div>
        )
      }
      <div className='overflow-hidden'>
        <div id={containerId} ref={ref} />
      </div>
    </div>
  );
}

Widget.defaultProps = {
  decorate: true,
};
