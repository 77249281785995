import { StarIcon } from '@heroicons/react/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/outline';

function Star() {
  return (
    <StarIcon className='w-6 h-6 text-yellow-500' />
  );
}

export function FiveStar() {
  return (
    <div className='flex items-center'>
      {
        [...Array(5).keys()].map((item) => (
          <Star key={item} />
        ))
      }
    </div>
  );
}

export const StarsByRating = ({ rating }: { rating: number }) => {
  let outlinedStars = 0;
  let solidStars = 0;

  if (rating >= 9) {
    solidStars = 5;
  } else if (rating >= 7) {
    outlinedStars = 1;
    solidStars = 4;
  } else {
    outlinedStars = 2;
    solidStars = 3;
  }

  return (
    <div className='flex items-center'>
      <>
        {
          [...Array(solidStars).keys()].map((item) => (
            <StarIcon
              key={item}
              className='w-5 h-5 md:w-6 md:h-6 text-yellow-500'
            />
          ))
        }
      </>
      <>
        {
          [...Array(outlinedStars).keys()].map((item) => (
            <StarIconOutline
              key={item}
              className='w-5 h-5 md:w-6 md:h-6 text-yellow-500'
            />
          ))
        }
      </>
    </div>
  );
};
