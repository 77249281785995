import { LoadingPattern } from './loading-pattern';

export const LoadingLayout = ({ emblemUrl, emblemAlt, children }: RedirectLoadingProps) => (
  <div className='flex flex-col justify-between h-full'>
    <div className='flex flex-col items-center justify-end gap-y-12 px-8 py-10 h-4/6'>
      <img src={emblemUrl} alt={emblemAlt} className='h-16' />
      <div className='flex flex-col items-center gap-y-4'>
        {children}
      </div>
    </div>
    <div className='h-2/6 flex items-end justify-end'>
      <div className='w-full max-h-[60px]'>
        <LoadingPattern width={660} numberOfLines={4} />
      </div>
    </div>
  </div>
);

type RedirectLoadingProps = { emblemUrl?: string; emblemAlt?: string; children: any; };
LoadingLayout.defaultProps = { emblemUrl: '/logo.png', emblemAlt: 'Comparison411 Logo' };
