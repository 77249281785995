'use client';

import Image from 'next/image';
import { PropsWithChildren, useEffect, useState } from 'react';
import Logo from '../../../public/logo.svg';

type VerificationStatus = 'pending' | 'human' | 'bot';

interface Props {
    source: string;
    destination: string;
    productId: string;
}

function VerificationSpinner({ children }: PropsWithChildren<any>) {
  return (
    <div className='flex justify-center items-center h-screen mx-5 -mt-20 sm:mt-0'>
      <div className='bg-white overflow-hidden md:rounded w-full h-full max-h-[400px] md:w-[600px] shadow-xl shadow-indigo-900/30 border-t border-gray-100'>
        <div className='flex flex-col justify-between'>
          <div className='flex flex-col items-center justify-end gap-y-12 px-8 pt-12 pb-5'>
            <Image
              priority
              src={Logo}
              alt='Comparison411 Logo'
              className='h-16'
            />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

function LoadingAnimation() {
  return (
    <div className='flex items-center justify-center space-x-2'>
      <div
        className='w-4 h-4 animate-bounce rounded-full bg-blue-500'
        style={{ animationDelay: '0.1s' }}
      />
      <div
        className='w-4 h-4 animate-bounce ease-in-out rounded-full bg-blue-400'
        style={{ animationDelay: '0.2s' }}
      />
      <div
        className='w-4 h-4 animate-bounce delay-150 ease-in-out rounded-full bg-blue-200'
        style={{ animationDelay: '0.3s' }}
      />
    </div>
  );
}

function VerificationPendingPane() {
  return (
    <>
      <h2 className='text-xl text-black'>Verifying you are human</h2>
      <LoadingAnimation />
    </>
  );
}

function BotDetectedPane() {
  return (
    <>
      <h2 className='text-xl text-black'>
        Unable to handle your request. Please try again later.
      </h2>
      <h1 className='text-5xl'>❌</h1>
    </>
  );
}

function HumanDetectedPane() {
  return (
    <>
      <h2 className='text-xl text-black'>Redirecting...</h2>
      <LoadingAnimation />
    </>
  );
}

async function makeVerificationRequest(
  source: string,
  destination: string,
  productId: string,
) {
  const data = {
    destination, source, productId,
  };

  const response = await fetch('/api/verify', {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    cache: 'no-store',
  });

  return response.json();
}

export default function VerificationController({ destination, source, productId }: Props) {
  const [status, setStatus] = useState<VerificationStatus>('pending');

  useEffect(() => {
    makeVerificationRequest(source, destination, productId)
      .then((data) => {
        setStatus(data.disposition);
      });
  }, []);

  if (status === 'human') {
    window.location.href = destination;
  }

  return (
    <VerificationSpinner>
      {{
        pending: <VerificationPendingPane />,
        human: <HumanDetectedPane />,
        bot: <BotDetectedPane />,
      }[status]}
    </VerificationSpinner>
  );
}
