import { createRef, ReactChildren, ReactChild } from 'react';

type TooltipProps = {
    children: ReactChildren | ReactChild;
    tooltipText: string;
};

export function Tooltip({ children, tooltipText }: TooltipProps) {
  const tipRef = createRef<HTMLDivElement>();

  function handleMouseEnter() {
    tipRef.current.style.opacity = '1';
    tipRef.current.style.marginLeft = '20px';
  }

  function handleMouseLeave() {
    tipRef.current.style.opacity = '0';
    tipRef.current.style.marginLeft = '10px';
  }

  return (
    <div
      className='relative flex items-center'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={tipRef}
        className='z-20 absolute whitespace-no-wrap bg-gray-100 text-gray-700 text-xs px-4 py-2 rounded flex items-center transition-all duration-150 w-96 shadow'
        style={{ right: '100%', opacity: 0 }}
      >
        <div
          className='bg-gray-100 h-3 w-3 absolute z-0'
          style={{ right: '-6px', transform: 'rotate(45deg)' }}
        />
        {tooltipText}
      </div>
      {children}
    </div>
  );
}
