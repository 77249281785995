import camelcaseKeys from 'camelcase-keys';
import * as R from 'ramda';
import * as process from 'process';
import { FUNCTIONS_ENDPOINT } from './constants';

const resolveIpAddress = (req) => {
  if (process.env.NODE_ENV === 'development') {
    return '91.196.69.61';
  }
  return req.headers['x-real-ip'] || req.connection.remoteAddress;
};

export const geolocate = async (req) => {
  const ipAddress = resolveIpAddress(req);

  if (ipAddress) {
    const response = await fetch(`${FUNCTIONS_ENDPOINT}/validation/lookup-ip-location?ip=${ipAddress}`);
    const { valid, location } = await response.json();
    if (!valid) return {};
    return {
      city: location?.city,
      countryIsoCode: location?.country,
      zip: location?.postal,
    };
  }

  return {};
};

export const preprocessProducts = (products: any[], countryIsoCode?: string): any[] => {
  const results = camelcaseKeys(products, { deep: true, stopPaths: ['features'] });
  const mappedResults = results.map(({
    landingPageUrl, landingPageUrlGb, costPerClick, costPerClickGb, ...product
  }) => {
    if (!countryIsoCode) return { landingPageUrl, costPerClick, ...product };
    const urls = { GB: landingPageUrlGb };
    const costPerClicks = { GB: costPerClickGb };
    const landingPageUrlResult: string = R.path([countryIsoCode], urls) ?? landingPageUrl;
    const costPerClickResult: number | null = R.path([countryIsoCode], costPerClicks) ?? costPerClick ?? null;
    let productResult: any = product;
    if (landingPageUrlResult === 'false') productResult = R.dissoc('landingPageUrl', product);
    else if (landingPageUrlResult) productResult = R.assoc('landingPageUrl', landingPageUrlResult, product);
    return R.assoc('costPerClick', costPerClickResult, productResult);
  });
  return mappedResults.filter(({ allowedCountries, disallowedCountries }) => {
    if (R.isNil(countryIsoCode)) return true;
    if ((disallowedCountries ?? []).includes(countryIsoCode)) return false;
    if (R.isNil(allowedCountries)) return true;
    return allowedCountries.includes(countryIsoCode);
  });
};
export const preprocessProduct = (product: any, countryIsoCode?: string): any | undefined => preprocessProducts([product], countryIsoCode)?.[0];
