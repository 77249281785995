import { SessionData, SessionStore } from 'next-session/lib/types';
import {
  DeleteItemCommand, DynamoDBClient, GetItemCommand, PutItemCommand,
} from '@aws-sdk/client-dynamodb';
import { C411_SESSION_TABLE, DYNAMO_DB_CONFIG } from './dynamodb';

/**
 * Manages a user session storing the session inside of dynamodb.
 */
export default class DynamoDbStore implements SessionStore {
  dynamoDb: DynamoDBClient;

  constructor() {
    this.dynamoDb = new DynamoDBClient(DYNAMO_DB_CONFIG);
  }

  /**
   * Get's the current session, if the session has expired delete it.
   * @param sid the id of the session to be fetched.
   * @returns a promise containing either the session data or null if non existent.
   */
  async get(sid: string): Promise<SessionData | null> {
    const session = await this.fetch(sid);
    if (session) {
      if (session.cookie.expires
            && new Date(session.cookie.expires).getTime() <= Date.now()
      ) {
        await this.destroy(sid);
        return null;
      }
      return session;
    }
    return null;
  }

  /**
   * Fetches the session data from DynamoDB and deserializes it.
   * @param sid the id of the session to be fetched.
   * @returns a promise contianing either a session or null if non existent.
   */
  async fetch(sid: string): Promise<SessionData | null> {
    const result = await this.dynamoDb.send(new GetItemCommand({
      TableName: C411_SESSION_TABLE,
      Key: { sessionId: { S: sid } },
    }));
    return result.Item ? JSON.parse(result.Item.data.S) : null;
  }

  /**
   * Upserts session data for a given session id.
   * @param sid the id of the session
   * @param sess an object containing the updated session data.
   */
  async set(sid: string, sess: SessionData) {
    const now = new Date().getTime();
    await this.dynamoDb.send(new PutItemCommand({
      TableName: C411_SESSION_TABLE,
      Item: {
        sessionId: { S: sid },
        createdAt: { N: sess.createdAt ? sess.createdAt : now.toString() },
        lastUpdated: { N: new Date().getTime().toString() },
        data: { S: JSON.stringify(sess) },
        // eslint-disable-next-line no-bitwise
        epochHours: { S: sess.epochHours ? sess.epochHours : (~~(now / 3_600_000)).toString() },
      },
    }));
  }

  /**
   * deletes a session by removing it from dynamodb
   * @param sid the id of the session
   */
  async destroy(sid: string) {
    await this.dynamoDb.send(new DeleteItemCommand({
      TableName: C411_SESSION_TABLE,
      Key: { sessionId: { S: sid } },
    }));
  }

  /**
   * Creates a new session for the first time.
   * @param sid the id of the session
   * @param sess the session to be created.
   */
  async touch(sid: string, sess: SessionData) {
    await this.set(sid, sess);
  }
}
