import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { GEMINI_PIXEL_ID, GEMINI_PROJECT_ID } from '@lib';

type UseBeaconProps = {
  url: string;
  costPerClick: number | null;
  conversionGoalGoogle: string | null;
  conversionGoalBing: string | null;
  expectedRevenuePerClick: number | null;
};

declare global {
  interface Window {
    snaptr: any;
    ttq: any;
    fbq: any;
    qp: any;
    rdt: any;
    dotq: any;
    uetq: any;
  }
}

const sendGoogleAdsBeacon = (url, conversionGoalGoogle: string | null, expectedRevenuePerClick: number | null) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
  // @ts-ignore
  gtag('js', new Date());
  // @ts-ignore
  gtag('config', 'AW-966612198');
  // @ts-ignore
  gtag('event', 'conversion', {
    send_to: `AW-966612198/${conversionGoalGoogle === null || conversionGoalGoogle === '' ? 'JBaJCJu5gooDEOap9cwD' : conversionGoalGoogle}`,
    ...(expectedRevenuePerClick === null ? {} : { value: expectedRevenuePerClick, currency: 'USD' }),
  });
};

const sendGemeniAdsBeacon = (costPerClick) => {
  window.dotq = window.dotq || [];
  window.dotq.push(
    {
      projectId: GEMINI_PROJECT_ID,
      properties: {
        pixelId: GEMINI_PIXEL_ID,
        qstrings: {
          et: 'custom',
          ec: 'View content', // The object you want to track. For example, button.
          ea: 'view content', // The type of interaction the user takes with the category object. For example, click.
          el: 'view content', // Useful to label details about the event. For example, Product demo
          ...(R.isNil(costPerClick) ? {} : { gv: costPerClick }), // conversion value
        },
      },
    },
  );
};

const bingAdsOutboundClick = (conversionGoal: string, expectedRevenuePerClick: number | null) => {
  window.uetq = window.uetq || [];
  if (expectedRevenuePerClick) window.uetq.push('event', conversionGoal, { revenue_value: expectedRevenuePerClick, currency: 'USD' });
  else window.uetq.push('event', conversionGoal);
};

export function useBeacons({
  url, costPerClick, conversionGoalGoogle, conversionGoalBing, expectedRevenuePerClick,
}: UseBeaconProps) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!url) return;
    setLoading(true);

    if (window.uetq && conversionGoalBing) bingAdsOutboundClick(conversionGoalBing, expectedRevenuePerClick);
    if (window.snaptr) window.snaptr('track', 'AD_CLICK');
    if (window.ttq) window.ttq.track('InitiateCheckout');
    if (window.fbq) window.fbq('track', 'InitiateCheckout');
    if (window.qp && typeof window.qp === 'function') window.qp('track', 'Generic');
    if (window.rdt) window.rdt('track', 'Lead', R.isNil(costPerClick) ? undefined : { currency: 'USD', value: costPerClick });
    sendGemeniAdsBeacon(costPerClick);
    sendGoogleAdsBeacon(url, conversionGoalGoogle, expectedRevenuePerClick);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [url]);

  return loading;
}
