import { LoadingLayout } from '@components';

export const WidgetLoading = () => (
  <div className='bg-white overflow-hidden md:rounded w-full h-full h-[600px] md:w-[660px] shadow-xl shadow-gray-400/60 border-t border-gray-100'>
    <LoadingLayout>
      <h1 className='text-2xl font-medium text-[#565966]'>
        Hang Tight!
      </h1>

      <p className='text-[#565966] text-base text-center'>
        We are working on your request.
      </p>
    </LoadingLayout>
  </div>
);
