import * as process from 'process';

export const CMS_ENDPOINT = process.env.NEXT_PUBLIC_CMS_ENDPOINT;
export const FUNCTIONS_ENDPOINT = process.env.NEXT_PUBLIC_FUNCTIONS_ENDPOINT;
export const MONDAY_COM_TOKEN = process.env.NEXT_PUBLIC_MONDAY_COM_TOKEN;
export const MONDAY_COM_BOARD_ID = process.env.NEXT_PUBLIC_MONDAY_COM_BOARD_ID;
export const MONDAY_COM_API = process.env.NEXT_PUBLIC_MONDAY_COM_API;

export const C411_SEARCH_ENDPOINT = process.env.NEXT_PUBLIC_C411_SEARCH_ENDPOINT;
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
